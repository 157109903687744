<template>
  <div class="main">
    <TableAdvance :dataProvider="dataProvider" :chartConfig="configuration" />
  </div>
</template>


<script>
import { makeDataProvider } from '../common.js'
export default {
  props: ['configuration', 'dataSource'],
  data() {
    return { dataProvider: [] }
  },
  components: { advance },
  created() {
    console.log('========table==========', this)
    if (
      this.dataSource &&
      this.dataSource.headers &&
      this.dataSource.headers.length
    ) {
      if (this.dataSource.rows.length) {
        this.dataProvider = this.dataSource.rows
      } else {
        this.dataProvider = makeDataProvider(this.dataSource)
      }
    }
    console.log(`[ this.dataProvider]: `, this.dataProvider)
  }
}
</script>